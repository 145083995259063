html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

div[data-ui-id="stream-media-container"] > div > video {
    object-fit: contain!important;
}